import React from 'react';

import pageStyle from '../assets/css/page.module.css';
import style from '../assets/css/main.module.css';
import Menu from '../components/menu';
import Footer from '../components/footer';
import HeroPage from '../components/hero.page';
import Hamburger from '../components/hamburger';
import SEO from '../components/seo';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../assets/css/alice-carousel-custom.css';

import imgHero from '../assets/img/heroes/clients.jpg';

import clientToyota from '../assets/img/clients/clientToyota.jpg';
import clientLexus from '../assets/img/clients/clientLexus.jpg';
import clientRightInk from '../assets/img/clients/clientRightInk.jpg';
import clientFlyingFish from '../assets/img/clients/clientFlyingFish.jpg';
import clientTouchEnglishAndGo from '../assets/img/clients/clientTouchEnglishAndGo.jpg';
import clientEBQuad from '../assets/img/clients/clientEBQuad.jpg';
import clientTT2000 from '../assets/img/clients/clientTT2000.jpg';
import clientFannyWautelet from '../assets/img/clients/clientFannyWautelet.jpg';
import clubBNI from '../assets/img/clients/clubBNI.jpg';
import clubSynergie from '../assets/img/clients/clubSynergie.jpg';
import partner44 from '../assets/img/clients/partner44.jpg';
import partnerRaal from '../assets/img/clients/partnerRaal.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

class References extends React.Component {
  items = [
    <a
      className={pageStyle.carousel_link}
      href="http://www.toyota.be"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={clientToyota} className={pageStyle.client_carousel_img} alt="Logo Toyota" />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="http://www.lexus.be"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={clientLexus} className={pageStyle.client_carousel_img} alt="Logo Lexus" />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="http://www.right-ink.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={clientRightInk} className={pageStyle.client_carousel_img} alt="Logo Right Ink" />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="http://www.flyingfish.agency/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={clientFlyingFish}
        className={pageStyle.client_carousel_img}
        alt="Logo Flying Fish"
      />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="https://www.touch-english-and-go.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={clientTouchEnglishAndGo}
        className={pageStyle.client_carousel_img}
        alt="Logo Touch English and Go"
      />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="https://www.ebquad.be/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={clientEBQuad} className={pageStyle.client_carousel_img} alt="Logo EB Quad" />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="https://www.tt2000.be/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={clientTT2000} className={pageStyle.client_carousel_img} alt="Logo TT200" />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="http://www.fannywautelet.be/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={clientFannyWautelet}
        className={pageStyle.client_carousel_img}
        alt="Logo Fanny Wautelet"
      />
    </a>
  ];

  items2 = [
    <a
      className={pageStyle.carousel_link}
      href="https://bnibelgique.be/la-louviere-pros-actifs/fr/index"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={clubBNI} className={pageStyle.client_carousel_img} alt="Logo BNI" />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="http://pme-synergie.org/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={clubSynergie} className={pageStyle.client_carousel_img} alt="Logo Synergie" />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="https://raal.be/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={partnerRaal} className={pageStyle.client_carousel_img} alt="Logo Raal" />
    </a>,
    <a
      className={pageStyle.carousel_link}
      href="https://www.club44.be/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={partner44} className={pageStyle.client_carousel_img} alt="Logo Club 44" />
    </a>
  ];
  state = {
    currentIndex: 0,
    currentIndex2: 0,
    responsive: { 0: { items: 2 }, 768: { items: 4 } },
    galleryItems: this.galleryItems(),
    galleryItems2: this.galleryItems2()
  };

  slideTo = i => this.setState({ currentIndex: i });

  onSlideChanged = e => this.setState({ currentIndex: e.item });

  slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 });

  slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 });

  thumbItem = (item, i) => <span onClick={() => this.slideTo(i)}>* </span>;
  galleryItems() {
    return this.items.map(i => <div key={i}> {i}</div>);
  }
  slideTo2 = i => this.setState({ currentIndex2: i });

  onSlideChanged = e => this.setState({ currentIndex2: e.item });

  slideNext2 = () => this.setState({ currentIndex2: this.state.currentIndex2 + 1 });

  slidePrev2 = () => this.setState({ currentIndex2: this.state.currentIndex2 - 1 });

  thumbItem = (item, i) => <span onClick={() => this.slideTo2(i)}>* </span>;
  galleryItems2() {
    return this.items2.map(i => <div key={i}> {i}</div>);
  }

  render() {
    const { galleryItems, galleryItems2, responsive, currentIndex, currentIndex2 } = this.state;
    return (
      <div id="top">
        <Menu />
        <SEO
          title="Références | Agence digitale | MDS Digital Agency"
          keywords="clients"
          description="Découvrez toute la panoplie de clients avec qui nous collaborons. Ils nous font confiance : Toyota, Lexus, EB Quad, Touch English and GO, etc."
        />
        <div className={style.menu_mobile}>
          <Hamburger />
        </div>
        <HeroPage img={imgHero} />

        <article className={pageStyle.article} style={{ paddingBottom: '50px' }}>
          <h2 className={pageStyle.header_uppercase}>Ils nous font confiance</h2>
          <div>
            <AliceCarousel
              mouseDragEnabled
              items={galleryItems}
              duration={500}
              responsive={responsive}
              autoPlay={true}
              autoPlayInterval={2000}
              fadeOutAnimation={true}
              dotsDisabled={true}
              buttonsDisabled={true}
              slideToIndex={currentIndex}
            />
            <div style={{ textAlign: 'right' }}>
              <button className={pageStyle.carousel_prev_next} onClick={() => this.slidePrev()}>
                <FontAwesomeIcon icon={faCaretLeft} />
              </button>
              <button className={pageStyle.carousel_prev_next} onClick={() => this.slideNext()}>
                <FontAwesomeIcon icon={faCaretRight} />
              </button>
            </div>
          </div>
          <div className={pageStyle.client_flex} />
          <h2 className={pageStyle.header_uppercase}>Partenaires & Business Club</h2>
          <div>
            <AliceCarousel
              mouseDragEnabled
              items={galleryItems2}
              responsive={responsive}
              autoPlay={true}
              duration={500}
              autoPlayInterval={2300}
              fadeOutAnimation={true}
              dotsDisabled={true}
              buttonsDisabled={true}
              slideToIndex={currentIndex2}
            />
          </div>
        </article>

        <Footer />
      </div>
    );
  }
}

export default References;
